import { formLoaderActions } from "redux/slices/formLoader";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { faqsAction } from "redux/slices/Faqs";

const url = "/faqs";

const FaqsService = {
  addFaqs: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addFaqs`, data)
    );
    if (success) {
      dispatch?.(faqsAction.addFaqs(success.data.data));
      navigate?.("/faqs");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getFaqs: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(faqsAction.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getFaqsPaginated`, data)
    );
    if (success) {
      const payload = success.data.data;
      dispatch?.(faqsAction.getFaqs(payload));
    }

    dispatch?.(faqsAction.setLoading(false));

    return [success, error];
  },

  updateFaqs: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updateFaqs`, data)
    );
    if (success) {
     const payload = success.data.data.faqs;

     dispatch?.(faqsAction.updateFaqs(payload));      
    }
    return [success, error];
  },

  getFaqsbyID: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getFaqsbyID`, data)
    );

    if (success) {
      const payload = success.data.data.faqs;

      dispatch?.(faqsAction.updateFaqs(payload));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
};

export default FaqsService;
