import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import OrderService from "services/api/order.service";
import { IOrderDetails } from "interfaces/order.interface";


interface OrderDetailState {
  orderDetails: Record<string, any>; 
  userDetails: Record<string, any>;
  serviceProvider: Record<string, any>;
  packageDetails: Record<string, any>;
}

const OrderDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const  _id  = useParams<{ _id: string }>();
  const singleOrder: IOrderDetails = useAppSelector(
    (state) => state.order.singleOrder
  );

  const [state, setState] = useState<OrderDetailState>({
    orderDetails: {},
    userDetails: {},
    serviceProvider: {},
    packageDetails: {},
  });

  useEffect(() => {
    if (_id) {
      OrderService.getOrderDetail(_id, dispatch);
    }
  }, [dispatch, _id]);

  useEffect(() => {
    if (singleOrder) {
      setState({
        orderDetails: singleOrder.orderDetails || {},
        userDetails: singleOrder.userDetails || {},
        serviceProvider: singleOrder.serviceProvider || {},
        packageDetails: singleOrder.packageDetails || {},
      });
    }
  }, [singleOrder]);

  const renderCardSection = (title: string, details: Record<string, any>) => (
    <Card
      sx={{ marginBottom: "20px", borderRadius: "16px", minWidth: "400px" }}
    >
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        {Object.entries(details).map(([key, value]) => (
          <div key={key}>
            <Typography>
              <strong>{key}:</strong> {value?.toString()}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </div>
        ))}
      </CardContent>
    </Card>
  );

  return (
    <div style={{ padding: "20px" }}>
      <GoBack path="/orders" title="orders" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        {renderCardSection("Order Details", state.orderDetails)}
        {renderCardSection("User Details", state.userDetails)}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        {renderCardSection("Service Provider", state.serviceProvider)}
        {renderCardSection("Package Details", state.packageDetails)}
      </Box>
    </div>
  );
};

export default OrderDetail;
