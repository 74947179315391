import {  fields } from ".";
import { useAppDispatch } from "redux/hooks";
import TemplateForm from "components/templates/TemplateForm";
import { contentAction } from "redux/slices/content";

export default function ContentFilter({ type, page, pageSize }: any) {
  const form = "ContentFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    let data: any = { page: page, pageSize: pageSize };
    const { searchTerm } = values;
    if (searchTerm) {
      data.searchTerm = searchTerm;
      data.page = 1;
    }

    dispatch(
      contentAction.setFilters({
        data,
        type,
      })
    );
  };

  return (
    <div className="filters displayFlex">
      <TemplateForm
        form={form}
        TemplateFormFields={fields}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
