import React, { forwardRef } from "react";
import AutoCompleteDropdown from "../../atoms/AutoCompleteDropdown/AutoCompleteDropdown";

const AutoCompleteDropdownRedux = forwardRef(
  ({ input, handleBlur, handleFocus, ...rest }: any, ref) => {
    return (
      <AutoCompleteDropdown
        {...rest}
        {...input}
        ref={ref}
        onBlur={(e: { preventDefault: () => void }) => {
          handleBlur?.(e);
          e.preventDefault();
        }}
        onFocus={handleFocus}
      />
    );
  }
);

export default AutoCompleteDropdownRedux;