import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import ErrorService from "services/helper/error.service";
import SentryService from "services/helper/sentry.service";
import ErrorBoundary from "components/atoms/ErrorBoundary";

ErrorService.init();
SentryService.init();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);


reportWebVitals();
