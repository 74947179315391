import { formLoaderActions } from "redux/slices/formLoader";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { userActions } from "redux/slices/user";
import { reset } from "redux-form";

const url = "/user";

const UserService = {
  getUserbyID: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(userActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getUserById`, data)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.setSingleUser(user));
    }

    dispatch?.(userActions.setLoading(false));

    return [success, error];
  },
  deleteUser: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`user/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(userActions.deleteUser(id));
    }
    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  createUser: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/register`, data)
    );
    if (success) {
      navigate?.("/account-management");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getUsers: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(userActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getUsers`, data)
    );

    if (success) {
      const { users, totalCount } = success.data.data;
      dispatch?.(
        userActions.setUsers({
          users: users,
          count: totalCount,
        })
      );
    }

    dispatch?.(userActions.setLoading(false));

    return [success, error];
  },
  addUser: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addUser`, data)
    );
    if (success) {
      // const { user } = success.data.data;
      dispatch?.(reset("AddUserForm"));

    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  updateUser: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.put(`user/updateUser`, data)
    );
    if (success) {
      const { user } = success.data.data;
      dispatch?.(userActions.updateUser({ user }));
      navigate("/users");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};
export default UserService;
