export { default, ticketActions, ticketSlice } from "./ticketSlice";

export interface ITicketState {
  tickets: [];
  ticket: [];
  chat: [];
  description: string;
  loading: boolean;
  count: number;
  tab: number;
  searchQuery: any;
}

export interface SetPagePayload {
  page: number;
  type: TicketType;
}

export interface SetTicketFiltersPayload {
  data?: any;
  type: TicketType;
  page?: number;
  pageSize?: number;
  newData?: any;
}

export enum ETicket {
  name = "name",
}

export type TicketType = keyof typeof ETicket;