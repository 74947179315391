import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { required } from "utils/validate.util";

export { default } from "./ChatForm";

export const chatField: ReduxFormField[] = [
  {
    name: "text",
    label: "text",
    validate: [required],
    component: InputRedux,
    cellProps: { lg: 12 },
    InputProps: { multiline: true, rows: 8 },
  },
];
