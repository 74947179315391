export { default, userActions, userSlice } from "./userSlice";

export interface AuthState {
  user: any;
  loading: boolean;

  users: any[];
  userOptions: any[];
  singleUser: any;
  count: number;
  email: any;
  searchQuery: any;
  tab: number;
}


export enum EUser {
  email = "email",
}

export interface SetPagePayload {
  page: number;
  type: UserType;
}

export interface SetFiltersPayload {
  data?: any;
  type: UserType;
  newData?: any;
}

export interface IUsers {
  filters: any;
  count: number;
  bookings: any[];
  loading: boolean;
  current_filters: any;
}

export type UserType = keyof typeof EUser;

