export interface orderState {
  tab: number;
  loading: boolean;
  order: [];
  singleOrder: any;
  updatedOrder: {};
  adminAddedOrder: {};
  count: number;
  searchQuery: any;
}


export interface SetPagePayload {
  page: number;
  type: OrderType;
}

export interface SetFiltersPayload {
  data?: any;
  type: OrderType;
  newData?: any;
}

export enum EOrder {
  email = "email",
}

export interface IUsers {
  filters: any;
  count: number;
  bookings: any[];
  loading: boolean;
  current_filters: any;
}

export type OrderType = keyof typeof EOrder;

export { orderAction, orderSlice } from "./orderSlice";
