import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PackageState, SetPackageFiltersPayload } from ".";

  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;


const initialState: PackageState = {
  packages: [],
  updatedPackage: {},
  packageDetailPackages: [],
  packageDetailServiceProvider: [],
  loading: false,
  searchQuery: { page: 1, pageSize: pageSize },
  count: 0,
  tab: 0,
  testimonials: [],
  faqs:[],
};

export const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
      state.searchQuery = { ...state.searchQuery, tab: action.payload };
    },
    getTestimonial: (state, action: PayloadAction<any | null>) => {
      const {count, testimonial} = action.payload
      state.testimonials = testimonial;
      state.count = count;
    },
    addPackage: (state, action) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    getPackage: (state, action) => {
      state.packages = action.payload;
    },
    getPackageDetail: (state, action) => {
      state.packageDetailPackages = action.payload.packages;
      state.packageDetailServiceProvider = action.payload.serviceProvider;
    },
    setPackages: (
      state,
      action: PayloadAction<{ packages: any; count: number }>
    ) => {
      const { packages, count } = action.payload;
      state.packages = packages;
      state.count = count;
    },

    setFilters: (state, action: PayloadAction<SetPackageFiltersPayload>) => {
      const { data, newData } = action.payload;
      if (data) {
        state.searchQuery = data;
      } else {
        state.searchQuery = newData;
      }
    },

    updatePackage: (state, action) => {
      state.updatedPackage = action.payload;
    },
  },
});

export const packageReducer = packageSlice.reducer;
export const packageAction = packageSlice.actions;
