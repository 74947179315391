import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import { AiFillEdit, AiFillEye, AiTwotoneEdit } from "react-icons/ai";
import PackageService from "services/api/package.service";




const AllTestimonialsList = ({ testimonials }: any) => {
  const loading = useAppSelector((state) => state.package.loading);
  const dispatch = useAppDispatch();

  const handleStatusChange = (_id: string, status: string) => {
    const data = { id: _id, status: status };
    PackageService.updateTestimonialStatus(data, dispatch);
  }
  return (
    <div>
      {loading && CircleLoader}

      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            container
            coloumns={8}
            loading={false}
            message="No Order available"
            length={testimonials.length}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Rating</StyledTableCell>
                <StyledTableCell>Review</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testimonials.map((testimonial: any, index: any) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{testimonial.name}</StyledTableCell>
                  <StyledTableCell>{testimonial.rating}</StyledTableCell>
                  <StyledTableCell>{testimonial.review}</StyledTableCell>
                  <StyledTableCell>{testimonial.status}</StyledTableCell>

                  <StyledTableCell>
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={testimonials._id}
                        onChange={(e) =>
                          handleStatusChange(
                            testimonial._id,
                            e.target.value as string
                          )
                        }
                        label="Status"
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="approved">Approved</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllTestimonialsList;
