import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ServiceProviderState } from ".";

const initialState: ServiceProviderState = {
  serviceProvider: [],
  category: [],
  package: [],
  getCategory: [],
  getProvider: [],
  allServices: [],
  loading: false,
};

export const serviceProviderSlice = createSlice({
  name: "serviceProvider",
  initialState,
  reducers: {
    addServiceProvider: (state, action) => {
      state.serviceProvider = action.payload;
    },
    addCategory: (state, action) => {
      state.category = action.payload;
    },

    addPackage: (state, action) => {
      state.package = action.payload;
    },
    getCategory: (state, action) => {
      state.getCategory = action.payload;
    },
    getProvider: (state, action) => {
      state.getProvider = action.payload;
    },
    getServices: (state, action) => {
      state.allServices = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const serviceProviderReducer = serviceProviderSlice.reducer;
export const serviceProviderAction = serviceProviderSlice.actions;
