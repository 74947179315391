import React, { useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import moment from "moment";
import BasicCard from "components/templates/BasicCard";
import ChatForm from "./ChatForm";
import TicketService from "services/api/ticket.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { IMessage, ChatPageProps } from "interfaces/ticket.interface";
import { useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";

const ChatPage = () => {
  const dispatch = useAppDispatch();
  const { ticketId } = useParams();

  useEffect(() => {
    TicketService.getChat(ticketId, dispatch);
  }, [ticketId, dispatch]);

  const handleSubmit = (values: any) => {
    const chatValues: IMessage = {
      ...values,
      senderType: "admin",
      ticketId: ticketId,
    };
    TicketService.addChat(chatValues, dispatch);
  };

  const changeTicketStatus = () => {

    TicketService.changeTicketStatus({ _id: ticketId }, dispatch);
  }

  const chatState = useAppSelector((state) => state.ticket.chat) as IMessage[];
  const chatDescription = useAppSelector((state) => state.ticket.description);

  const sortedMessages = [...chatState].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  let lastDate = "";

  return (
    <>
      <Container maxWidth="lg">
        <GoBack path="/tickets" title="tickets" />
        <div style={{ marginTop: "7rem" }}>
          <BasicCard>
            <div className="chat__container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="header"
              >
                <p>Ticket: {chatDescription}</p>
                <Button
                  onClick={() => changeTicketStatus()}
                  color="info"
                  variant="outlined"
                  type="submit"
                >
                  close ticket
                </Button>
              </div>

              <div className="chat__section">
                {sortedMessages.map((message, index) => {
                  const messageDate = moment(message.createdAt).format(
                    "YYYY-MM-DD"
                  );
                  const showDate = messageDate !== lastDate;
                  lastDate = showDate ? messageDate : lastDate;

                  return (
                    <React.Fragment key={index}>
                      {showDate && (
                        <div className="date-display text-center">
                          {moment(message.createdAt).format("DD-MM-YYYY")}
                        </div>
                      )}
                      <Grid container>
                        <Grid
                          item
                          lg={12}
                          style={{
                            textAlign:
                              message.senderType === "user" ? "left" : "right",
                          }}
                        >
                          {message.senderType === "user" ? (
                            <div className="message__container">
                              <div className="left">
                                <div className="message">{message.text}</div>
                                <div className="info text-center">
                                  {moment(message.createdAt).format("HH:mm:ss")}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="message__container justify-content-end">
                              <div className="left">
                                <div className="message">{message.text}</div>
                                <div className="info text-center">
                                  {moment(message.createdAt).format("HH:mm:ss")}
                                </div>
                              </div>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </div>
              <ChatForm onSubmit={handleSubmit} />
            </div>
          </BasicCard>
        </div>
      </Container>
    </>
  );
};

export default ChatPage;
