import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutoCompleteDropdown({
  options,
  onChange,
  ...rest
}: any) {
  const handleChange = (event: any, newValue: any) => {

    if (onChange && newValue) {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      className="autocompleteDropdownSearch"
      disablePortal
      options={options}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} />}
      {...rest}
    />
  );
}
