import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";
import { Link } from "react-router-dom";

import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import {  AiFillEdit } from "react-icons/ai";
import Button from "components/atoms/Button";

const ContentList = ({ content }: any) => {
  return (
    <div>
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            container
            coloumns={4}
            loading={false}
            message="No Campaign available"
            length={10}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Text</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content.map((cont: any, index: any) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <img
                      width={100}
                      height={80}
                      src={process.env.REACT_APP_API_URL + cont.image}
                      alt=""
                      style={{ borderRadius: "10px" }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{cont.categoryID.name}</StyledTableCell>
                  <StyledTableCell>
                    <div dangerouslySetInnerHTML={{ __html:cont.content }} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Link
                      to={`/add-content/${cont._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        sx={{ minWidth: "auto" }}
                        color="info"
                        variant="outlined"
                      >
                        <AiFillEdit />
                      </Button>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ContentList;
