import { formLoaderActions } from "redux/slices/formLoader";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { ticketActions } from "redux/slices/ticket";

const url = "/ticket";

const TicketService = {
  addTicket: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addticket`, data)
    );

    if (success) {
      dispatch?.(ticketActions.setTicketsData(data));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  addChat: async (data: any, dispatch: any) => {
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.put(`${url}/addChat`, data)
    );

    if (success) {
      dispatch?.(ticketActions.setTicketsData(data));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getTicket: async (data: any, dispatch: AppDispatch) => {
    http.setJWT()
    dispatch?.(ticketActions.setLoading(true));
    
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAllTicketsPaginated`, data)
    );

    if (success) {
      const payload = success.data.data;
      dispatch?.(ticketActions.setTickets(payload));
    }

    dispatch?.(ticketActions.setLoading(false));

    return [success, error];
  },

  getChat: async (ticketId: any, dispatch: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getChat`, { ticketId })
    );

    if (success) {
      const payload = success.data.data.data;
      dispatch?.(ticketActions.setChat(payload));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  changeTicketStatus: async (data: any, dispatch: AppDispatch) => {
    http.setJWT()
    dispatch?.(ticketActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/changeTicketStatus`, data)
    );

    if (success) {
      const { tickets } = success.data.data;
      dispatch?.(ticketActions.setTicketsData(tickets));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
};
export default TicketService;
