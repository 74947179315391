import "./Header.scss";
import { Avatar } from "@mui/material";
import AuthService from "services/api/auth.service";
import BasicMenu from "components/atoms/BasicMenu";
import { NavLink, useLocation } from "react-router-dom";

import buyfans from "../../../assets/logo.svg";
export default function Header() {
  const { pathname } = useLocation();

  if (pathname === "/") return <></>;

  return (
    <header>
      <div className="navbar">
        <div className="logologo">
          <NavLink to="/orders">
            <img src={buyfans} alt="BuyFans Logo" className="img" />
          </NavLink>
        </div>
        <div className="links">
          <NavLink to="/orders" className="anchor">
            Orders
          </NavLink>
          <NavLink to="/packages" className="anchor">
            Packages
          </NavLink>
          <NavLink to="/tickets" className="anchor">
            Tickets
          </NavLink>
          <NavLink to="/users" className="anchor">
            Users
          </NavLink>
          <NavLink to="/testimonials" className="anchor">
            Testimonials
          </NavLink>
          <NavLink to="/faqs" className="anchor">
            FAQs
          </NavLink>
          <NavLink to="/content" className="anchor">
            Content
          </NavLink>
        </div>
        <div className="account">
          <BasicMenu
            list={[
              {
                text: "Logout",
                onClick: () => AuthService.logout(),
              },
            ]}
            avatar={false}
            color="#ffffff"
          >
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
          </BasicMenu>
        </div>
      </div>
    </header>
  );
}
