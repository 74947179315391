import { formLoaderActions } from "redux/slices/formLoader";
import { modalActions } from "redux/slices/modal";
import { AppDispatch } from "redux/store";
import http from "../api/http.service";
import Promisable from "./promisable.service";

const url = "/upload/view";

const ImageService = {
  getImageFileFromBlob: async ({ blob, name, type }: any) => {
    const response = await fetch(blob);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get("content-type") || type,
    });
  },
  upload: async (fileName: any, data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${fileName}`, data)
    );

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
};

export default ImageService;
