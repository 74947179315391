import { Container } from "@mui/material";
import { useAppDispatch } from "redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import AddContentForm from "./AddContentForm";
import UpdateContentForm from "./UpdateContentForm";
import ContentService from "services/api/content.service";


const AddContent = () => {
  const { _id }  = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
const handleSubmit = async (values: any) => {
  const updatedValues = { ...values };

  if (updatedValues.userID && updatedValues.userID.value) {
    updatedValues.userID = updatedValues.userID.value;
  }

  if (_id) {
     updatedValues._id = _id;
    ContentService.updateContent(updatedValues, dispatch, navigate);
  } else {
    ContentService.addContent(updatedValues, dispatch, navigate);
  }
};

 
  return (
    <Container maxWidth="lg">
      <AddContentForm onSubmit={handleSubmit} />
      {_id && <UpdateContentForm />}
    </Container>
  );
};

export default AddContent;
