import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GoBack from "components/atoms/GoBack";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PackageService from "services/api/package.service";
import { IPackages } from "interfaces/package.interface";
import Box from "@mui/material/Box";
import DetailTable from "./DetailTable";// Import Box from Material-UI

const PackageDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const payload = { service: id };

  useEffect(() => {
    PackageService.getPackageDetail(payload, dispatch);
  }, []);

  const packages: IPackages[] = useAppSelector(
    (state) => state.package?.packageDetailPackages
  );
  const serviceProvider: any = useAppSelector(
    (state) => state.package?.packageDetailServiceProvider
  );
    const renderPackageDetails = (pkg: IPackages, index: number) => (
      <React.Fragment key={index}>
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Name
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.name}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Rate
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.rate}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Min
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.min}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Max
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.max}
        </Typography>
        <Divider sx={{ my: 1 }} />

        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          service
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.service}
        </Typography>
        <Divider sx={{ my: 1 }} />

        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Type
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.type}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Refill
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.refill ? "True" : "False"}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Cancel
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.cancel ? "True" : "False"}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          Quantity
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg?.quantity}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          packageType
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {pkg.packageType}
        </Typography>
      </React.Fragment>
    );
  return (
    <>
      <GoBack path="/packages" title="Packages" />

      <Box sx={{ margin: "0 auto", maxWidth: 1200, padding: 2 }}>
        <Grid container spacing={2}>
          {/* Service Provider Details on the Left */}
          <Grid item xs={12} sm={4}>
            <DetailTable
              title={`${serviceProvider?.name} Details`}
              detail={{
                Name: serviceProvider?.service?.name,
                Rate: serviceProvider?.service?.rate,
                Min: serviceProvider?.service?.min,
                Max: serviceProvider?.service?.max,
                Service: serviceProvider?.service?.service,
                Type: serviceProvider?.service?.type,
                Refill: serviceProvider?.service?.refill ? "True" : "False",
                Cancel: serviceProvider?.service?.cancel ? "True" : "False",
                dripfeed: serviceProvider?.service?.dripfeed,
                category: serviceProvider?.service?.category,
              }}
            />
          </Grid>

          {packages.map((pkg, index) => (
            <Grid item key={index} xs={12} sm={4}>
              <DetailTable
                title={`BuyFans Details (Package ${index + 1})`}
                detail={{
                  Name: pkg.name,
                  Rate: pkg.rate,
                  Min: pkg.min,
                  Max: pkg.max,
                  Service: pkg.service,
                  Type: pkg.type,
                  Refill: pkg.refill ? "True" : "False",
                  Cancel: pkg.cancel ? "True" : "False",
                  Quantity: pkg.quantity,
                  PackageType: pkg.packageType,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default PackageDetail;
