import { Pagination, Stack } from "@mui/material";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect, useState } from "react";
import CircleLoader from "components/atoms/CircleLoader";
import Tabs from "components/templates/Tabs/Tabs";
import { useNavigate } from "react-router-dom";
import ContentFilter from "./ContentFilter/ContentFilter";
import ContentList from "./ContentList";
import { contentAction } from "redux/slices/content";
import ContentService from "services/api/content.service";

const Content = ({ type }: any) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { contents, loading, tab, searchQuery, count } = useAppSelector((state) => state.content)

  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;

  const handlePageChange = (e: any, value: any) => {
    let newData = { ...searchQuery };
    newData.page = value;
    newData.pageSize = pageSize;
    dispatch(contentAction.setFilters({ newData, type }));
    setPage(value);
  };

  useEffect(() => {
    ContentService.getContent(searchQuery, dispatch);
  }, [searchQuery, dispatch]);

  return (
    <div style={{ padding: "0 25px" }} className="order__container">
      {loading && <CircleLoader />}

      <Banner heading="content">
        <Stack direction="row" spacing={2}>
          {<ContentFilter page={page} type={type} pageSize={pageSize} />}
          <Button
            onClick={() => navigate("/add-content")}
            color="info"
            variant="outlined"
            type="submit"
          >
            Add Content
          </Button>
        </Stack>
      </Banner>
      <BasicCard>
        <Tabs
          value={tab}
          onChange={(tab) => dispatch(contentAction.setTab(tab))}
          tabs={[
            {
              label: "All",
              element: <ContentList content={contents} />,
            },
            {
              label: "Instagram",
              element: <ContentList content={contents} />,
            },
            {
              label: "Youtube",
              element: <ContentList content={contents} />,
            },
            {
              label: "Tiktok",
              element: <ContentList content={contents} />,
            },
            {
              label: "Facebook",
              element: <ContentList content={contents} />,
            },
          ]}
        />
      </BasicCard>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          style={{ width: "fit-content" }}
          defaultPage={1}
          onChange={handlePageChange}
          count={
            contents.length > 0 ? Math.ceil(count / searchQuery?.pageSize) : 0
          }
        />
      </div>
    </div>
  );
};

export default Content;
