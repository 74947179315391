export interface FaqsState {
  updatedContent: any;
  loading: boolean;
  searchQuery: { pageSize: number; page: number; tab?: number };
  count: any;
  tab: any;
  contents: [];
  content:null
}

export interface SetPagePayload {
  page: number;
  type: FaqsType;
}

export interface SetFaqsFiltersPayload {
  data?: any;
  type: FaqsType;
  page?: number;
  pageSize?:number
  newData?: any;
}

export enum EFaq {
  name = "name",
}



export type FaqsType = keyof typeof EFaq;

export { contentAction, contentSlice } from "./contentSlice";
