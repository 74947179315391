import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SetFiltersPayload, orderState } from ".";

  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;


const initialState: orderState = {
  tab: 0,
  loading: false,
  order: [],
  singleOrder: {},
  updatedOrder: {},
  adminAddedOrder: {},
  searchQuery: { page: 1, pageSize: pageSize },
  count: 0,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
      state.searchQuery = { ...state.searchQuery, tab: action.payload };
    },
    getOrders: (state, action) => {
      state.order = action.payload;
    },
    getOrderDetail: (state, action) => {
      state.singleOrder = action.payload;
    },
    updateOrder: (state, action) => {
      state.updatedOrder = action.payload;
    },
    addAdminOrder: (state, action) => {
      state.adminAddedOrder = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOrders: (
      state,
      action: PayloadAction<{ order: any; count: number }>
    ) => {
      const { order, count } = action.payload;
      state.order = order;
      state.count = count;
    },

    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { data, newData } = action.payload;
      if (data) {
        state.searchQuery = data;
      } else {
        state.searchQuery = newData;
      }
    },
  },
});

export const orderReducer = orderSlice.reducer;
export const orderAction = orderSlice.actions;
