import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { change } from "redux-form";
import { IContent} from "interfaces/faqs.interface";
import ContentService from "services/api/content.service";

export default function UpdateContentForm() {
  const form = "AddContentForm";
  const dispatch = useAppDispatch();
  const {content}:any = useAppSelector(
    (state) => state.content
  );
  
  const _id = useParams();

  useEffect(() => {
    ContentService.getContentbyID( _id , dispatch);
  }, [dispatch, _id]);


  useEffect(() => {
    if (!content) return;
   dispatch(change(form, "categoryID", content.categoryID?._id));
   dispatch(change(form, "content", content.content));
  //  dispatch(change(form, "image", content.image));
  }, [dispatch, content]);

  return null;
}
