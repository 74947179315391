import { formLoaderActions } from "redux/slices/formLoader";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { contentAction } from "redux/slices/content";
import ImageService from "services/helper/image.service";

const url = "/content";

const ContentService = {
  
  addContent: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    let image = await ImageService.getImageFileFromBlob(data.image);

    http.setJWT();
    http.setMultiPart();
    const formData = new FormData();
    formData.append("categoryID", data.categoryID);
    formData.append("content", data.content);
    formData.append("file", image);

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addContent`, formData)
    );
    if (success) {
      dispatch?.(contentAction.addContent(success.data.data));
      navigate?.("/content");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getContent: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(contentAction.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getContent`, data)
    );
    if (success) {
      const { contents } = success.data.data;
      dispatch?.(
        contentAction.getContent({
          contents: contents.filteredResults,
          count: contents.totalCount,
        })
      );
    }

    dispatch?.(contentAction.setLoading(false));

    return [success, error];
  },

  updateContent: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
        dispatch?.(formLoaderActions.setLoading(true));

    let image;
    
    if (data.image) image = await ImageService.getImageFileFromBlob(data.image);

      http.setJWT();
      http.setMultiPart();
      const formData = new FormData();
      formData.append("categoryID", data.categoryID);
      formData.append("content", data.content);
     if (image) formData.append("file", image);
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updateContent/${data._id}`, formData)
    );
    if (success) {
      const payload = success.data.data.content;

      dispatch?.(contentAction.updateContent(payload));
      navigate?.("/content");
    }
        dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getContentbyID: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getContentby`, data)
    );

    if (success) {
      const {content} = success.data.data;

      dispatch?.(contentAction.setContent(content));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
};

export default ContentService;
