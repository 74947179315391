import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { change } from "redux-form";
import FaqsService from "services/api/faqs.service";
import { IFaqs } from "interfaces/faqs.interface";

export default function UpdateFaqsForm() {
  const form = "AddFaqsForm";
  const dispatch = useAppDispatch();
  const updatedFaq: IFaqs[] = useAppSelector(
    (state) => state.faq.updatedFaqs
  );
  
  const _id = useParams();

  useEffect(() => {
    FaqsService.getFaqsbyID( _id , dispatch);
  }, [dispatch, _id]);


  useEffect(() => {
    if (!updatedFaq) return;
    updatedFaq.map((data) => {
    dispatch(change(form, "categoryID", data.categoryID?._id));
    dispatch(change(form, "status", data.status));
    dispatch(change(form, "question", data.question));
    dispatch(change(form, "answer", data.answer));
    })

  }, [dispatch, updatedFaq]);

  return null;
}
