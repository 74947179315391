import AddContent from "pages/AddContent";
import AddFaqs from "pages/AddFaqs";
import AddOrder from "pages/AddOrder";
import ChatPage from "pages/Chat/Chat";
import Content from "pages/Content";
import OrderDetail from "pages/OrderDetail";
import PackageDetail from "pages/PackageDetail";
import Testimonials from "pages/Testimonials";
import { lazy } from "react";

const Users = lazy(() => import("pages/Users"));
const Orders = lazy(() => import("pages/Orders"));
const Subscriptions = lazy(() => import("pages/Subscriptions"));
const Cancel = lazy(() => import("pages/Cancel"));
const DripFeed = lazy(() => import("pages/DripFeed"));
const Payments = lazy(() => import("pages/Payments"));
const Tickets = lazy(() => import("pages/Tickets"));
const Faqs = lazy(() => import("pages/Faqs"));
const Packages = lazy(() => import("pages/Packages"));
const Custom = lazy(() => import("pages/Custom"));
const Login = lazy(() => import("pages/Login"));
const AddUser = lazy(() => import("pages/AddUser"));
const AddServiceProvider = lazy(() => import("pages/AddServiceProvider"));
const AddCategory = lazy(() => import("pages/AddCategory"));
const AddPackage = lazy(() => import("pages/AddPackage"));


export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const private_routes: IRoute[] = [
  { path: "/add-serviceProvider", element: <AddServiceProvider /> },
  { path: "/add-category", element: <AddCategory /> },
  { path: "/testimonials", element: <Testimonials /> },

  { path: "/packages", element: <Packages /> },
  { path: "/add-package", element: <AddPackage /> },
  { path: "/add-package/:_id", element: <AddPackage /> },
  { path: "/package-detail/:id", element: <PackageDetail /> },

  { path: "/users", element: <Users /> },
  { path: "/add-user", element: <AddUser /> },
  { path: "/user/:_id", element: <AddUser /> },

  { path: "/tickets", element: <Tickets /> },
  { path: "/ticket/:ticketId", element: <ChatPage /> },

  { path: "/faqs", element: <Faqs /> },
  { path: "/add-faqs", element: <AddFaqs /> },
  { path: "/add-faqs/:_id", element: <AddFaqs /> },

  { path: "/content", element: <Content /> },
  { path: "/add-content", element: <AddContent /> },
  { path: "/add-content/:_id", element: <AddContent /> },

  { path: "/orders", element: <Orders /> },
  { path: "/order/:_id", element: <OrderDetail /> },
  { path: "/add-order", element: <AddOrder /> },
  { path: "/add-order/:_id", element: <AddOrder /> },

  // { path: "/subscriptions", element: <Subscriptions /> },
  // { path: "/drip-feed", element: <DripFeed /> },
  // { path: "/cancel", element: <Cancel /> },
  // { path: "/payment", element: <Payments /> },
  // { path: "/custom", element: <Custom /> },
];

export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
];
