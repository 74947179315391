import { Container } from "@mui/material";
import { useAppDispatch } from "redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import UpdateOrderForm from "./UpdateOrderForm";
import OrderService from "services/api/order.service";
import AddOrderForm from "./AddOrderForm";

const AddOrder = () => {
  const { _id }  = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
const handleSubmit = async (values: any) => {
  const updatedValues = { ...values };

  if (updatedValues.userID && updatedValues.userID.value) {
    updatedValues.userID = updatedValues.userID.value;
  }

  if (_id) {
     updatedValues._id = _id;
    OrderService.updateOrder(updatedValues, dispatch, navigate);
  } else {
    OrderService.addAdminOrder(updatedValues, dispatch, navigate);
  }
};

 
  return (
    <Container maxWidth="lg">
      <AddOrderForm onSubmit={handleSubmit} />
      {_id && <UpdateOrderForm />}
    </Container>
  );
};

export default AddOrder;
