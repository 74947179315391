import { useState, useEffect, useCallback, useRef } from "react";
import { useAppSelector } from "redux/hooks";
import { DataBuyFansServices } from "interfaces/package.interface";
import { OptionTypes } from "interfaces/package.interface";
import { debounce } from "services/helper/debounce.service";
import AutoCompleteDropdownRedux from "components/molecules/AutoCompleteDropdownRedux";
import { useAppDispatch } from "redux/hooks";
import { serviceProviderAction } from "redux/slices/serviceProviders";
import { userActions } from "redux/slices/user";
import { fetchUsersService } from "services/helper/fetchUsers.service";

export default function SelectUser({ ...rest }) {
  const dispatch: any = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<OptionTypes[]>([]);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");


    const data: DataBuyFansServices = {
      page,
      searchTerm: inputValue,
      pageSize: 30,
    };
  
  useEffect(() => {
    fetchUsersService(data, dispatch).then((response:any) => {
      setOptions(response?.optionsUsers);
      dispatch?.(userActions.setUsers(response?.users));
    });
  }, [ dispatch]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
    } else {
      debouncedFetchData(data);
    }
  }, [inputValue]);



  const debouncedFetchData = useCallback(
    debounce((data) => {
      setPage(1);

      return fetchUsersService(data, dispatch).then((response: any) => {
        dispatch?.(serviceProviderAction.getServices(response?.users));
        setOptions(response?.optionsUsers);
      });
    }, 1000),
    []
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const node = event.currentTarget;
    const threshold = 5;

    if (
      !loading &&
      node.scrollTop + node.clientHeight >= node.scrollHeight - threshold
    ) {
      setLoading(true);
      const currentScrollTop = node.scrollTop;

      fetchUsersService(data, dispatch).then((response: any) => {
        setOptions((prev) => {
          const newOptions = [...prev, ...response?.optionsUsers];
          dispatch(
            serviceProviderAction.getServices([...prev, ...response?.users])
          );
          return newOptions;
        });

        setTimeout(() => {
          node.scrollTop = currentScrollTop;
          setLoading(false);
        }, 0);
      });
    }
  };

  if (options?.length > 0 && options) {
    return (
      <>
        <p className="servicesDropDownHeading">Users</p>
        <AutoCompleteDropdownRedux
          options={options}
          onInputChange={(event: any, newInputValue: string) => {
            if (newInputValue && newInputValue !== "") {
              if (event && event.type === "change") {
                setInputValue(newInputValue);

                if (inputValue && inputValue !== "") {
                  debouncedFetchData({ ...data, searchTerm: newInputValue })
                    .then((response: any) => {})
                    .catch((error) => console.log("error", error));
                }
              }
            }
          }}
          onChange={handleScroll}
          getOptionLabel={(option: { label: OptionTypes }) => option.label}
          ListboxProps={{ onScroll: handleScroll }}
          {...rest}
        />
      </>
    );
  } else{
    return (
      <>
        <p className="servicesDropDownHeading">Users</p>

        <AutoCompleteDropdownRedux disabled />
      </>
    );
  }
}
