import { email, minLength8, number, required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import SelectRedux from "components/molecules/SelectRedux";
import SelectPackage from "./SelectPackage";
import SelectUser from "./SelectUser";


export { default } from "./AddOrderForm";

export const fields: ReduxFormField[] = [
  {
    name: "packageID",
    label: "packages",
    component: SelectPackage,
    cellProps: { md: 6 },
  },

  {
    name: "userID",
    label: "user",
    component: SelectUser,

    cellProps: { md: 6 },
  },

  {
    name: "quantity",
    label: "quantity",
    component: InputRedux,
    InputProps: { type: "number" },
    cellProps: { lg: 6 },
  },
  {
    name: "amount",
    label: "amount",
    component: InputRedux,
    cellProps: { lg: 6 },
    InputProps: { type: "number" },
  },
  {
    name: "status",
    label: "Status",
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "Partial", label: "Partial" },
        { value: "Blocked", label: "Blocked" },
        { value: "Cancelled", label: "Cancelled" },
        { value: "Completed", label: "Completed" },
        { value: "Pending", label: "Pending" },
      ],
    },
    cellProps: { md: 6 },
  },
  {
    name: "orderEmail",
    label: "orderEmail",
    component: InputRedux,
    cellProps: { lg: 6 },
    InputProps: { type: "email" },
  },

  {
    name: "orderUsername",
    label: "orderUserName",
    component: InputRedux,
    cellProps: { lg: 6 },
    InputProps: { type: "string" },
  },
];
