import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

type DetailProps = {
  detail: any;
  title: string;
};

const DetailTable: React.FC<DetailProps> = ({ detail, title }) => {
  return (
    <TableContainer component={Paper} sx={{ margin: 1 }}>
      <Typography variant="h6" sx={{ padding: 2 }}>
        {title}
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          {Object.entries(detail).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </TableCell>
              <TableCell align="right">{String(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailTable;
