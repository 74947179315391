import { formLoaderActions } from "redux/slices/formLoader";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "services/helper/promisable.service";
import { orderAction } from "redux/slices/Orders";
import { reset } from "redux-form";

const url = "/order";

const OrderService = {
  getOrders: async (data: any, dispatch?: AppDispatch) => {
    http.setJWT()
    dispatch?.(orderAction.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAllOrdersPaginated`, data)
    );

    if (success) {
      const payload = success.data.data;
      dispatch?.(orderAction.setOrders(payload));
    }

    dispatch?.(orderAction.setLoading(false));

    return [success, error];
  },

  getOrderDetail: async (data: any, dispatch?: AppDispatch) => {
    http.setJWT();

    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getOrderDetail`, data)
    );
    if (success) {
      const {order} = success.data.data;
      dispatch?.(orderAction.getOrderDetail(order));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  addAdminOrder: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    http.setJWT();

    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addAdminOrder`, data)
    );
    if (success) {
      dispatch?.(orderAction.addAdminOrder(success.data.data));
      dispatch?.(reset("AddOrderForm"));

      navigate?.("/orders");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  updateOrder: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.put(`${url}/updateOrder`, data)
    );
    if (success) {
      const { user } = success.data.data;
    }
    return [success, error];
  },
  updateOrderStatus: async (
    dispatch?: AppDispatch,
    navigate?: any
  ) => {
    http.setJWT();
    dispatch?.(orderAction.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/updateOrderStatus`)
    );
    if (success) {
      const { orders } = success.data.data;
    }
    dispatch?.(orderAction.setLoading(false));
    return [success, error];

  },

  getOrderbyID: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getOrderById`, data)
    );

    if (success) {
      const { order } = success.data.data;
      dispatch?.(orderAction.updateOrder(order));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
};

export default OrderService;
