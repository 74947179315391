import { ITicketState, SetTicketFiltersPayload } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;


const initialState: ITicketState = {
  ticket: [],
  loading: false,
  tickets: [],
  chat: [],
  description: "",
  count: 0,
  searchQuery: { page: 1, pageSize: pageSize },
  tab:0,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
      state.searchQuery = { ...state.searchQuery, tab: action.payload };
    },
    setTicketsData: (state, action: PayloadAction<[]>) => {
      state.ticket = action.payload;
    },
    getTicketsData: (state, action: PayloadAction<[]>) => {
      state.tickets = action.payload;
    },
    setChat: (state, action: any) => {
      state.chat = action.payload?.messages;
      state.description = action.payload?.description;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setTickets: (
      state,
      action: PayloadAction<{ tickets: any; count: number }>
    ) => {
      const { tickets, count } = action.payload;
      state.tickets = tickets;
      state.count = count;
    },

    setFilters: (state, action: PayloadAction<SetTicketFiltersPayload>) => {
      const { data, newData } = action.payload;
      if (data) {
        state.searchQuery = data;
      } else {
        state.searchQuery = newData;
      }
    },
  },
});

const ticketReducer = ticketSlice.reducer;

export const ticketActions = ticketSlice.actions;
export default ticketReducer;
