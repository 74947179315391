import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import SelectCategory from "../AddContentForm/SelectCategory";
import TextEditor from "components/atoms/TextEditor";
import FileUploadRedux from "components/molecules/FileUploadRedux";

export { default } from "./UpdateContentForm";

export const fields: ReduxFormField[] = [
  {
    name: "categoryID",
    label: "Category",
    component: SelectCategory,
    cellProps: { md: 6 },
  },
  {
    name: "image",
    label: "Image",
    component: FileUploadRedux,
    cellProps: { lg: 6 },
  },

  {
    name: "content",
    label: "content",
    component: TextEditor,
    InputProps: { multiline: true },
    cellProps: { lg: 12 },
  },
];