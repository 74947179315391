import { formLoaderActions } from "redux/slices/formLoader";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { packageAction } from "redux/slices/package";
import { modalActions } from "redux/slices/modal";

const url = "/package";

const PackageService = {
  getTestimonial: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(packageAction.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getTestimonialAdmin`, data)
    );
    const payload = success.data.data;
    dispatch?.(packageAction.getTestimonial(payload));

    dispatch?.(packageAction.setLoading(false));

    return [success, error];
  },

  addTestimonial: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(packageAction.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addTestimonial`, data)
    );
    if (success) {
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(packageAction.setLoading(false));

    return [success, error];
  },

  updateTestimonialStatus: async (data:any, dispatch?: AppDispatch) => {
    http.setJWT();
    dispatch?.(packageAction.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updateTestimonialStatus`, data)
    );
    if (success) {
      const { testimonial } = success.data.data;
    }
    dispatch?.(packageAction.setLoading(false));
    return [success, error];
  },

  addPackage: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addPackage`, data)
    );
    if (success) {
      dispatch?.(packageAction.addPackage(success.data.data));
      navigate?.("/packages");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getPackage: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(packageAction.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getPackagesPaginated`, data)
    );
    if (success) {
      const payload = success.data.data;
      dispatch?.(packageAction.setPackages(payload));
    }

    dispatch?.(packageAction.setLoading(false));

    return [success, error];
  },

  getAllPackage: async (dispatch?: AppDispatch) => {
    dispatch?.(packageAction.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getPackage`)
    );
    if (success) {
      const { data } = success.data.data;
      dispatch?.(packageAction.getPackage(data));
    }

    dispatch?.(packageAction.setLoading(false));

    return [success, error];
  },
  getPackageDetail: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getPackageDetail`, data)
    );
    if (success) {
      const payload = success.data.data;
      dispatch?.(packageAction.getPackageDetail(payload));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getPackagebyID: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getPackagebyID`, data)
    );

    if (success) {
      const payload = success.data.data.packages;

      dispatch?.(packageAction.updatePackage(payload));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  updatePackage: async (data: any, dispatch?: AppDispatch, navigate?: any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.put(`${url}/updatePackage`, data)
    );
    if (success) {
      const { packages } = success.data.data;
    }
    return [success, error];
  },
};

export default PackageService;
