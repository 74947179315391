import { useEffect, useState } from "react";
import ServiceProviderService from "services/api/serviceProvider.service";
import SelectRedux from "components/molecules/SelectRedux";
import { SelectOption } from "components/atoms/Select";
import ICategoryOption from "interfaces/package.interface";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { change } from "redux-form";
import { serviceProviderAction } from "redux/slices/serviceProviders";

export default function SelectCategory(props: any) {
  const dispatch = useAppDispatch();

  const categoryOptions = useAppSelector(
    (state): ICategoryOption[] => state.serviceProvider.getCategory
  );

  useEffect(() => {
    ServiceProviderService.getCategory(dispatch);
  }, [dispatch]);

  const categoryList = categoryOptions?.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  return <SelectRedux {...props} options={categoryList} />;
}
