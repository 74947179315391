import { useEffect, useState } from "react";
import SelectRedux from "components/molecules/SelectRedux";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import PackageService from "services/api/package.service";

export default function SelectPackage(props: any) {
  const dispatch = useAppDispatch();

  const packages = useAppSelector((state) => state.package.packages)

  useEffect(() => {
    PackageService.getAllPackage(dispatch);
  }, [dispatch]);

  const packageList = packages?.map((pkg: any) => ({
    value: pkg._id,
    label:  pkg.service + " - "+ pkg.name +" (" + pkg.quantity + ")",
  }));

  return <SelectRedux {...props} options={packageList} />;
}
