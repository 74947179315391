import { reduxForm } from "redux-form";
import { chatField } from ".";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";

const ChatForm = ({ handleSubmit }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <ReduxFormFields fields={chatField} />
      <br />
      <Button color="info" variant="contained" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "ChatForm",
})(ChatForm);
