import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import SimpleModal from "./SimpleModal";

const ConfirmationForm = lazy(() => import("./ConfirmationForm"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  DELETE_CITY: "",
  DELETE_BANNER: "",
  DELETE_ZIPCODE: "",
  DELETE_FAQ: "",
  DELETE_CAMPAIGN: "",
  DELETE_CATEGORY: "",
  DELETE_PRODUCT: "",
  DELETE_ROLE: "",
  DELETE_SUBCATEGORY: "",
  DELETE_PRODUCT_TAG: "",
  DELETE_PRODUCT_MEDIA: "",
  DELETE_PRODUCT_CATEGORY: "",
  DELETE_STORE: "",
  DELETE_USER: "",
  DELETE_COUPON: "",
  DELETE_AllCOUPON: "",
  UPDATE_COUPON: "",
  SIMPLE_MODAL: <SimpleModal />,
  CONFIRMATION_FORM: <ConfirmationForm />,
};
