import { AuthState, SetFiltersPayload } from ".";
import LocalStorage from "utils/localstorage.util";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const user = LocalStorage.getItem("user");

  const pageSize: number = parseInt(
    process.env.REACT_APP_PAGE_SIZE || "defaultPageSize",
    30
  );
  
const initialState: AuthState = {
  user,
  users: [],
  userOptions: [],
  loading: false,
  singleUser: null,
  email: "",
  count: 0,
  tab:0,

  searchQuery: { page: 1, pageSize: pageSize },
};



export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
      state.searchQuery = { ...state.searchQuery, tab: action.payload };
    },
    logout: () => {},
    setUser: (state, action) => {
      state.user = action.payload;
      LocalStorage.setItem("user", state.user);
    },

    setSingleUser: (state, action) => {
      state.singleUser = action.payload;
    },
    setUsers: (
      state,
      action: PayloadAction<{ users: any[]; count: number }>
    ) => {
      const { users, count } = action.payload;
      state.users = users;
      state.count = count;
    },

    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { data, newData } = action.payload;
      if (data) {
        state.searchQuery = data;
      } else {
        state.searchQuery = newData;
      }
    },

    addUser: (state, action) => {
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    deleteUser: (state, action) => {
      const id = action.payload;
      state.users = state?.users?.filter((user) => {
        return user._id !== id;
      });
    },
    updateUser: (state, action) => {
      const { id, user } = action.payload;
      state.users.every(({ _id }, i) => {
        if (id === _id) {
          state.users[i] = user;
          return false;
        }
        return true;
      });
    },
  },
});

const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;
export default userReducer;
