import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaqsState, SetFaqsFiltersPayload  } from "./index";

  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;


const initialState: FaqsState = {
  updatedFaqs: [],
  loading: false,
  searchQuery: { page: 1, pageSize: pageSize },
  count: 0,
  tab: 0,
  faqs: [],
};

export const faqsSlice = createSlice({
  name: "Faqs",
  initialState,
  reducers: {
    addFaqs: (state, action) => {},

    getFaqs: (state, action) => {
      state.faqs = action.payload.faqs;
      state.count = action.payload.count;
    },

    setFaqs: (state, action: PayloadAction<{ faqs: any; count: number }>) => {
      const { faqs, count } = action.payload;
      state.faqs = faqs;
      state.count = count;
    },

    updateFaqs: (state, action) => {
      state.updatedFaqs = action.payload;
    },

    setFilters: (state, action: PayloadAction<SetFaqsFiltersPayload>) => {
      const { data, newData } = action.payload;
      if (data) {
        state.searchQuery = data;
      } else {
        state.searchQuery = newData;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
      state.searchQuery = { ...state.searchQuery, tab: action.payload };
    },
  },
});

export const faqsReducer = faqsSlice.reducer;
export const faqsAction = faqsSlice.actions;
