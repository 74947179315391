import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
// import formLoaderReducer from "./slices/formLoader";
import { orderReducer } from "./slices/Orders/orderSlice";
import formLoaderReducer from "./slices/formLoader/formLoaderSlice";
import { serviceProviderReducer } from "./slices/serviceProviders/serviceProviderSlice";
import { packageReducer } from "./slices/package/packageSlice";
import ticketReducer from "./slices/ticket/ticketSlice";
import userReducer from "./slices/user";
import { faqsReducer } from "./slices/Faqs/FaqsSlice";
import { contentReducer } from "./slices/content/contentSlice";


const appReducer = combineReducers({
  form: formReducer,

  formLoader: formLoaderReducer,
  auth: authReducer,
  modal: modalReducer,
  loader: loaderReducer,
  order: orderReducer,
  serviceProvider: serviceProviderReducer,
  package: packageReducer,
  ticket: ticketReducer,
  user: userReducer,
  faq: faqsReducer,
  content: contentReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
