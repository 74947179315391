import { formLoaderActions } from "redux/slices/formLoader";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { serviceProviderAction } from "../../redux/slices/serviceProviders/serviceProviderSlice";

const url = "/serviceProvider";

const ServiceProviderService = {
  addServiceProvider: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addServiceProvider`, data)
    );
    if (success) {
      dispatch?.(serviceProviderAction.addServiceProvider(success.data.data));
      navigate?.("/packages");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  addCategory: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addCategory`, data)
    );
    if (success) {
      dispatch?.(serviceProviderAction.addCategory(data));
      navigate?.("/packages");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getBuyFansServices: async (data: any, dispatch?: AppDispatch) => {
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getBuyFansServices`, data)
    );
    if (success) {
      const response = success.data.data.data;
    }
    return [success, error];
  },

  addPackage: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/addPackage`, data)
    );
    if (success) {
      dispatch?.(serviceProviderAction.addPackage(success.data.data));
      navigate?.("/packages");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCategory: async (dispatch?: AppDispatch) => {
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getCategory`)
    );
    if (success) {
      dispatch?.(serviceProviderAction.getCategory(success.data.data.data));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getProvider: async (dispatch?: AppDispatch) => {
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getProvider`)
    );
    if (success) {
      dispatch?.(serviceProviderAction.getProvider(success.data.data.data));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
};
export default ServiceProviderService;
