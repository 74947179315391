import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import SelectRedux from "components/molecules/SelectRedux";
import SelectCategory from "./SelectCategory";
import TextEditor from "components/atoms/TextEditor";
import InputRedux from "components/molecules/InputRedux";


export { default } from "./AddFaqsForm";

export const fields: ReduxFormField[] = [
  {
    name: "categoryID",
    label: "Category",
    component: SelectCategory,
    cellProps: { md: 6 },
  },
  {
    name: "status",
    label: "Status",
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "active", label: "Active" },
        { value: "blocked", label: "Blocked" },
      ],
    },
    cellProps: { md: 6 },
  },

  {
    name: "question",
    label: "Question",
    component: InputRedux,
    InputProps: { multiline: true },
    cellProps: { lg: 12 },
  },
  {
    name: "answer",
    label: "Answer",
    component: InputRedux,
    InputProps: { multiline: true },
    cellProps: { lg: 12 },
  },
];
