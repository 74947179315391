import { TemplateFormProps } from ".";
import Button from "components/atoms/Button";
import { InjectedFormProps, reduxForm } from "redux-form";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { Stack } from "@mui/material";

const TemplateForm = ({
  TemplateFormFields,
  handleSubmit,
  onClickReset,
  onClickExport,
}: TemplateFormProps & InjectedFormProps<{}, TemplateFormProps>) => {
  return (
    <form className="displayFlex" onSubmit={handleSubmit}>
      <ReduxFormFields fields={TemplateFormFields} />
      <br />
      <Stack>
        <div>
          <Button variant="contained" type="submit">
            Search
          </Button>

          {/* {onClickReset && (
            <Button
              type="reset"
              variant="outlined"
              onClick={onClickReset}
              sx={{ marginRight: "10px" }}
            >
              Reset
            </Button>
          )} */}
        </div>
      </Stack>
    </form>
  );
};

export default reduxForm<{}, TemplateFormProps>({ form: "TemplateForm" })(
  TemplateForm
);
