export interface PackageState {
  packages: [];
  packageDetailPackages: [];
  packageDetailServiceProvider: [];
  loading: boolean;
  count: number;
  updatedPackage: any;
  searchQuery: any;
  tab: number;
  testimonials: [];
  faqs: [];
}

export interface SetPagePayload {
  page: number;
  type: PackageType;
}

export interface SetPackageFiltersPayload {
  data?: any;
  type: PackageType;
  page?: number;
  pageSize?:number
  newData?: any;
}

export enum EPackage {
  name = "name",
}



export type PackageType = keyof typeof EPackage;

export { packageAction, packageSlice } from "./packageSlice";
