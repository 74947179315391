import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { change } from "redux-form";
import OrderService from "services/api/order.service";
import { IOrder } from "interfaces/order.interface";
import PackageService from "services/api/package.service";

export default function UpdateOrderForm() {
  const form = "AddOrderForm";
  const dispatch = useAppDispatch();
  const updatedOrder: IOrder = useAppSelector(
    (state) => state.order.updatedOrder
  );
  
  const _id = useParams();

  useEffect(() => {
    OrderService.getOrderbyID( _id , dispatch);
  }, [dispatch, _id]);

  const packages = useAppSelector((state) => state.package.packages);

  useEffect(() => {
    if (packages && packages.length == 0) {
      PackageService.getPackage({}, dispatch);
    }
  }, [packages, dispatch]);


  useEffect(() => {
    if (!updatedOrder) return;
    const {
      packageID,
      userID,
      quantity,
      amount,
      status,
      orderEmail,
      orderUsername,
    } = updatedOrder;

    dispatch(change(form, "packageID", packageID));
    dispatch(change(form, "userID", userID));
    dispatch(change(form, "quantity", quantity));
    dispatch(change(form, "amount", amount));
    dispatch(change(form, "status", status));
    dispatch(change(form, "orderEmail", orderEmail));
    dispatch(change(form, "orderUsername", orderUsername));
    
  }, [dispatch, updatedOrder]);

  return null;
}
