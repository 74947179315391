import BasicCard from "components/templates/BasicCard";
import Tabs from "components/templates/Tabs";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import CircleLoader from "components/atoms/CircleLoader";
import AllTestimonialsList from "./AllTestimonialsList";
import PackageService from "services/api/package.service";
import { packageAction } from "redux/slices/package";


 
const Testimonials = ({ type }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage, ] = useState(1);
  const { count, loading, tab, searchQuery, testimonials } = useAppSelector(
    (state: any) => state.package
  );
    
  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;

  useEffect(() => {
    PackageService.getTestimonial(searchQuery, dispatch);
  }, [dispatch, searchQuery, tab]);


  const handlePageChange = (e: any, value: any) => {
    const newData = { ...searchQuery };
    newData.page = value;
    newData.pageSize = pageSize;
    dispatch(packageAction.setFilters({ newData, type }));
    setPage(value);
  };

  return (
    <>
 
      {loading && <CircleLoader />}

      <div style={{ padding: "0 25px" }} className="order__container">
        <BasicCard>
          <Tabs
            value={tab}
            onChange={(tab) => dispatch(packageAction.setTab(tab))}
            tabs={[
              {
                label: "All",
                element: <AllTestimonialsList testimonials={testimonials} />,
              },
              {
                label: "pending",
                element: <AllTestimonialsList testimonials={testimonials} />,
              },
              {
                label: "approved",
                element: <AllTestimonialsList testimonials={testimonials} />,
              },
            ]}
          />
        </BasicCard>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            style={{ width: "fit-content" }}
            defaultPage={1}
            onChange={handlePageChange}
            count={
              testimonials.length > 0 ? Math.ceil(count / searchQuery.pageSize) : 0
            }
          />
        </div>
      </div>
    </>
  );
};

export default Testimonials;
