import { Button} from "@mui/material";
import GoBack from "components/atoms/GoBack";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import {  reduxForm } from "redux-form";
import { fields } from "./index";
import { useAppSelector } from "redux/hooks";
import Loader from "components/atoms/Loader";
import { useParams } from "react-router-dom";

const AddOrderForm = ({ handleSubmit }: any) => {
  const loading = useAppSelector((state) => state.formLoader.loading);
  	const { _id } = useParams();

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <GoBack path="/orders" title="Order" />

        <Banner heading={`${_id ? "Update" : "Add"} Order `} />

        <BasicCard>
          {loading && <Loader />}

          <ReduxFormFields fields={fields} />

          <br />
          <Button
            style={{ width: "170px" }}
            color="info"
            variant="contained"
            type="submit"
          >
            SAVE
          </Button>
        </BasicCard>
      </form>
    </div>
  );
};

export default reduxForm({ form: "AddOrderForm" })(AddOrderForm);
