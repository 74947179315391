import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import Promisable from "../helper/promisable.service";
import { authActions } from "redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";

const url = "/auth";

const AuthService = {
  login: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`admin/login`, data)
    );

    if (success) {
      const { jwtToken } = success.data.data;

      localStorage.setItem("token", `Bearer ${jwtToken}`);
       dispatch?.(authActions.setUser(data));
      navigate?.("/orders");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  logout: () => {
    const dispatch = getAppDispatch();

    localStorage.removeItem("token");

    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
  },
 
};
export default AuthService;
