import { DataBuyFansServices } from "interfaces/package.interface";
import UserService from "services/api/user.service";

export const fetchUsersService = async (
  data: DataBuyFansServices,
  dispatch: any
) => {
  const { pageSize , searchTerm } = data;

  if(!searchTerm) delete data.searchTerm

  const [success] = await UserService.getUsers(data, dispatch);
  const { users, totalCount } = success.data.data;

  if (users.length > 0) {

    let optionsUsers = users.map((user: any) => ({
      value: user._id,
      label: user.email,
    }));
      const totalUsers = Math.ceil(totalCount / pageSize);

      return { optionsUsers, totalUsers, users };
  } else {
    throw new Error("No errors with this name");
  }


};
