import { Container } from "@mui/material";
import { useAppDispatch } from "redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import FaqsService from "services/api/faqs.service";
import AddFaqsForm from "./AddFaqsForm";
import UpdateFaqsForm from "./UpdateFaqsForm";


const AddFaqs = () => {
  const { _id }  = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
const handleSubmit = async (values: any) => {
  const updatedValues = { ...values };

  if (updatedValues.userID && updatedValues.userID.value) {
    updatedValues.userID = updatedValues.userID.value;
  }

  if (_id) {
     updatedValues._id = _id;
    FaqsService.updateFaqs(updatedValues, dispatch, navigate);
  } else {
    FaqsService.addFaqs(updatedValues, dispatch, navigate);
  }
};

 
  return (
    <Container maxWidth="lg">
      <AddFaqsForm onSubmit={handleSubmit} />
      {_id && <UpdateFaqsForm />}
    </Container>
  );
};

export default AddFaqs;
