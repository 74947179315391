import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaqsState, SetFaqsFiltersPayload  } from "./index";

  const pageSize: any = process.env.REACT_APP_PAGE_SIZE || 30;


const initialState: FaqsState = {
  updatedContent: [],
  loading: false,
  searchQuery: { page: 1, pageSize: pageSize },
  count: 0,
  tab: 0,
  contents: [],
  content: null,
};

export const contentSlice = createSlice({
  name: "Content",
  initialState,
  reducers: {
    addContent: (state, action) => {},

    getContent: (state, action) => {
      state.contents = action.payload.contents;
      state.count = action.payload.count;
    },

    setContent: (
      state,
      action
    ) => {
      state.content = action.payload;
    },

    updateContent: (state, action) => {
      state.content = action.payload;
    },

    setFilters: (state, action: PayloadAction<SetFaqsFiltersPayload>) => {
      const { data, newData } = action.payload;
      if (data) {
        state.searchQuery = data;
      } else {
        state.searchQuery = newData;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
      state.searchQuery = { ...state.searchQuery, tab: action.payload };
    },
  },
});

export const contentReducer = contentSlice.reducer;
export const contentAction = contentSlice.actions;
