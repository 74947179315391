import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";


export { default } from "./ContentFilter";

export interface ContentFiltersProps {
  type: any;
  page: any;
  query?: any;
  pageSize?: any;
  handleResetquery?: () => void;
}

export const fields: ReduxFormField[] = [
  {
    name: "searchTerm",
    label: "",
    placeholder: "content category",
    component: InputRedux,
  },
];
